var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"form_container p-4 mb-6"},[_vm._m(0),_c('h1',{staticClass:"is-size-4 has-text-centered has-text-weight-light mt-0 mb-6"},[_vm._v(" Espace commerçant ")]),_c('div',{staticClass:"mb-6"},_vm._l((_vm.marketingItems),function(item){return _c('div',{key:item,staticClass:"is-flex mb-1"},[_c('b-icon',{staticClass:"mr-2",attrs:{"icon":"check","type":"is-success"}}),_c('p',{staticClass:"mb-0 is-size-6",staticStyle:{"line-height":"1.5em"}},[_vm._v(" "+_vm._s(item)+" ")])],1)}),0),_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.recaptcha.apply(null, arguments)}}},[_c('b-field',{attrs:{"label":"Email","custom-class":"is-small has-text-weight-light has-text-grey","type":{ 'is-danger': _vm.$v.form.email.$error },"message":[
        { 'Email invalide': _vm.$v.form.email.$error },
        { 'Champs requis': !_vm.$v.form.email.required && _vm.$v.form.email.$dirty },
      ]}},[_c('b-input',{attrs:{"rounded":"","placeholder":"johndoe@proxity.com","type":"email"},model:{value:(_vm.$v.form.email.$model),callback:function ($$v) {_vm.$set(_vm.$v.form.email, "$model", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"$v.form.email.$model"}})],1),_c('b-field',{attrs:{"label":"Mot de passe","custom-class":"is-small has-text-weight-light has-text-grey","type":{ 'is-danger': _vm.$v.form.password.$error },"message":[
        { 'Minimum 10 caractères': true },
        { 'Champs requis': !_vm.$v.form.password.required && _vm.$v.form.password.$dirty },
      ]}},[_c('b-input',{attrs:{"rounded":"","type":"password","password-reveal":""},model:{value:(_vm.$v.form.password.$model),callback:function ($$v) {_vm.$set(_vm.$v.form.password, "$model", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"$v.form.password.$model"}})],1),_c('b-field',{attrs:{"label":"Confirmation mot de passe","custom-class":"is-small has-text-weight-light has-text-grey","type":{ 'is-danger': _vm.$v.form.passwordConfirmation.$error },"message":[
        {
          'Les deux mots de passe doivent être identiques':
            _vm.$v.form.passwordConfirmation.$error
        },
      ]}},[_c('b-input',{attrs:{"rounded":"","type":"password","password-reveal":""},model:{value:(_vm.$v.form.passwordConfirmation.$model),callback:function ($$v) {_vm.$set(_vm.$v.form.passwordConfirmation, "$model", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"$v.form.passwordConfirmation.$model"}})],1),_c('b-notification',{staticClass:"mt-4",attrs:{"type":"is-light","aria-close-label":"Fermer message d'erreur","role":"alert","closable":""},model:{value:(_vm.isInfoMsgActive),callback:function ($$v) {_vm.isInfoMsgActive=$$v},expression:"isInfoMsgActive"}},[_vm._v(" "+_vm._s(_vm.error)+" ")]),_c('b-button',{staticClass:"mt-6",attrs:{"label":"Créer un compte","native-type":"submit","type":"is-secondary","expanded":"","rounded":"","icon-left":"rocket-launch-outline"}})],1)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"is-flex is-justify-content-center"},[_c('img',{staticClass:"proxity_logo",attrs:{"src":require("@/assets/img/icons-cashier.svg"),"alt":"Proxity logo","width":"300"}})])
}]

export { render, staticRenderFns }