
import { Component, Vue } from 'vue-property-decorator';
import {
  required,
  sameAs,
  minLength,
  email,
  maxLength,
} from 'vuelidate/lib/validators';
import authService from '@/services/auth/authService';

@Component
export default class SignUpForm extends Vue {
  public isInfoMsgActive = false;
  public error = '';
  public form: any = {
    email: '',
    password: '',
    passwordConfirmation: '',
    termsOfService: true,
  };
  public marketingItems: string[] = [
    'Profitez des bénéfices d\'une carte cadeau locale',
    'Une solution clé en main',
    'Une application simple d\'utilisation',
    'Sans abonnement',
    'Remboursement en moins d\'une semaine',
  ];

  validations(): any {
    return {
      form: {
        email: {
          required,
          email,
        },
        password: {
          required,
          maxLength: maxLength(64),
          minLength: minLength(10),
        },
        passwordConfirmation: {
          sameAsPassword: sameAs('password'),
        },
        termsOfService: {
          required,
          isTrue: (value: boolean) => value,
        },
      },
    };
  }
  async recaptcha(): Promise<any> {
    if (!this.$v.$invalid) {
      const loadingComponent = this.$buefy.loading.open({
        container: null,
      });
      try {
        await this.$recaptchaLoaded();
        const token = await this.$recaptcha('SignIn');
        const { data } = await authService.verifyCaptcha(token);
        if (data.success) {
          await this.signup();
        }
      } catch (error) {
        this.$buefy.snackbar.open({
          indefinite: true,
          type: 'is-danger',
          message: 'Une erreur est survenue.',
        });
      } finally { loadingComponent.close(); }
    }
  }
  async signup(): Promise<void> {
    const loadingComponent = this.$buefy.loading.open({
      container: null,
    });
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { passwordConfirmation, ...payload } = this.form;

    this.isInfoMsgActive = false;
    try {
      await authService.signUp(payload);
      this.$buefy.snackbar.open({
        message: `
          Un email de confirmation vient de vous être envoyé. <br>
          Consultez votre messagerie et cliquez sur le lien d'activation. <br>
        `,
        position: 'is-bottom-right',
        type: 'is-success',
        indefinite: true,
        actionText: 'ok',
      });
      this.$router.push({ name: 'Login' });
    } catch (error) {
      const { data } = error.response;

      this.error = Array.isArray(data.message) ? data.message[0] : data.message;
      this.isInfoMsgActive = true;
    } finally { loadingComponent.close(); }
  }
}
