
import { Component, Vue } from 'vue-property-decorator';
import SignUpForm from '@/components/SignUpFrom.vue';

@Component({
  components: {
    SignUpForm,
  },
})
export default class SignUp extends Vue {}
